import { Question  } from '../models/question';

/**
 * Bot service
 *
 * Overview: Provides Bot related services
 *
 */

export class BotService {
    /**
     * Calls bot API to ask question.
     * @param botKnowledgeBaseId API knowledgeBase Id
     * @param botAuthorizationEndpointKey API authorization key
     * @param question Model housing bot question API model
     * @returns Status 200 if returned
     */
    askQuestion(question: Question, episodeKey: string) {
        
        return new Promise((resolve, reject) => {
            const qnaEndpoint = 'https://chathak.netlify.app/api/qa';
            // console.log("episodeKey", episodeKey)
            // console.log("QUESTION", question.question)
            fetch(qnaEndpoint, {
                method: 'POST',
                body: JSON.stringify({
                    question: question.question,
                    episode: "episode:hak100" + episodeKey,
                    cta: true,
                    game: "HAK10"
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then((response) => {
                if (response.ok) {
                    return resolve(response);
                } else {
                    return reject(response);
                }
            }).catch(e => {
                console.log(e)
            });
        });
    }
}
