import React, { useState, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import store from 'store';
import CheeseburgerMenu from '../components/CheeseburgerMenu';
import LoginView from '../components/AshleysOffline/LoginView';
import MessagesView from '../components/AshleysOffline/MessagesView';

const PASSWORD = "saguaro";

export default function AshleysOfflineMessageView(props) {
	const [currentView, setCurrentView] = useState("lock")
	const [selectedConversation, setSelectedConversation] = useState(-1) 

	useEffect(() => { 
		
		const password = store.get("ashleyOfflinePortal")

		if (!(typeof password === 'undefined') && password ===PASSWORD) {
		  setCurrentView("home")
		}

	})

	const tapBack = () => {
	  setSelectedConversation(-1)
	}


    return (
        
		<Div100vh  dontresize="true" className={currentView === "lock" ? "h-full w-full flex flex-col ashleysOfflineMessageSite lockScreen" : "h-full w-full flex flex-col ashleysOfflineMessageSite"} id="ashleysOfflineMessageSite">            	
		
			{currentView === "lock" ? (
				<LoginView correctPw = {PASSWORD} goToHomeView={function() {setCurrentView("home")}} />
			) : (
				
				<MessagesView />
			)}


			
			{!(window.location.href.indexOf("ashleyoffline") > -1) && <img className=" close-icon "  alt="close-dt" onClick={props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s10/closebutton-black.svg"/>	}
			



        </Div100vh>
                
    )
}



