
import React from "react";
import BlogComment from "./BlogComment"

export default function BlogPostView(props) {

	const blogEntry = props.blogEntry
	const commentViews = blogEntry.comments.map((comment, index) => {
		return (
		  <BlogComment comment={comment} key={index}/> 
		)
	})


  return (

  <> 

	  <div className="blog_excerpt_container pb-6 md:pb-10">
	    {(blogEntry.imageURL) && (<img className="mt-3 md:mt-8" src={"https://cdn.huntakiller.com/huntakiller/"+blogEntry.imageURL} alt={blogEntry.title} />)}
	    <h3 className="font-bold text-2xl mt-2 mb-3 px-4 md:px-0 md:text-4xl md:mt-4 md:mb-6 ">{blogEntry.title}</h3>
	  	<p className="font-medium text-xs pb-2 px-4 md:px-0 md:text-lg md:pb-4">{"By "+blogEntry.author}</p>
	  	<div className="text-sm px-4 md:px-0 md:text-lg" dangerouslySetInnerHTML={{ __html: blogEntry.content }}></div>
	  </div>

	  <div className="blog_container_comments mt-6 md:mt-2">
	  	<h4 className="orange px-4 md:px-0 text-lg md:text-2xl">Comments</h4>
	  	{commentViews}
	  	<p className="italic text-center my-6 px-4 md:px-0 text-base md:text-xl md:my-8">Comment submission closed.</p>

	  </div>

	  <button onClick={props.tapBack} className="px-4 md:px-0 mb-6 orange focus:outline-none focus:shadow-outline underline uppercase">{'<<'}Back</button>

  </>);
  
}