import React from 'react'

/*import './menuContent.css'*/

export default function StorageMenuContent(props) {

  const items = [["Files","keepsafe-files-folder-icon.svg"]  ,["Recent","keepsafe-files-recent-icon.svg"],["Starred","keepsafe-files-starred-icon.svg"],["Recycled Bin","keepsafe-files-trash-icon.svg"],["Storage","keepsafe-files-storage-icon.svg"]]
    

  return (
    <ul className="menu">
      <li className="current" onClick={props.tapMenu} ><img src="https://cdn.huntakiller.com/huntakiller/s10/files-folder-icon-selected.svg"/>Files</li>
      <li className="" ><img src="https://cdn.huntakiller.com/huntakiller/s10/files-recent-icon.svg"/>Recent</li>
      <li className="" ><img src="https://cdn.huntakiller.com/huntakiller/s10/files-starred-icon.svg"/>Starred</li>
      <li className="" ><img src="https://cdn.huntakiller.com/huntakiller/s10/files-trash-icon.svg"/>Recycle Bin</li>   
    </ul>
  )
  
}
